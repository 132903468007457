#contact {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 1.719vw;
    grid-row-gap: 0px;
    margin-top: 5.208vw;
    line-height: 2.5;
    height: 58vw;
    text-align: center;
    /*color: rgba(0, 0, 0, 0.7);*/
    color: white;
    background-color: #171717;
    padding: 10.417vw 0px 5.208vw 0px;

}
#contact li{
    list-style: none;
    font-size: 0.938vw;
}
#contact a{
    /*color: rgba(0, 0, 0, 0.329);*/
    color: grey;
    transition: all linear 200ms;
    text-decoration: none;

}
#contact a:hover{
    color: gold;
    transition: all linear 200ms;
}
#contact h2{
    padding-bottom: 1.042vw;
}
#summary{
    text-align: center;
}

#form {
    margin-top: 0px;
    margin-bottom: 50px;
}
#form textarea{
    border: none;
    border-bottom: grey 3px solid;
    border-radius: 0.521vw;
    font-size: 0.938vw;
  }
  #form textarea:valid{
    border-bottom: gold 3px solid;
  }
   
  #form input{
    border: none;
    border-bottom: grey 3px solid;
    border-radius: 0.521vw;
    font-size: 0.938vw;

  }
 #form input:valid{
    border-bottom: gold 3px solid;
  }
  
  #form label{
    color: grey;
    font-size: 0.833vw;
  }

  #partners-links .partners-list {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    padding: 0;
  }
  
  #partners-links .partners-list li {
    margin: 0 10px;
  }

@media only screen and (max-width: 1130px){
    #contact{
        display: block;
        padding: 10px 0px;
        height: auto;
        overflow:hidden;

    }

    #form label{
        font-size: 16px;
      }

      #form{
        padding: 20px;
      }

    #contact li{
        font-size: 16px;
    }
    #form textarea,
    #form input{
        font-size: 16px;
    }
    #summary,
    #general,
    #webLinks{
        padding-bottom: 50px;
    }
    #partners-links .partners-list {
      display: block;
      justify-content: none;
      list-style-type: none;
      padding: 0;
    }
    
    #partners-links .partners-list li {
      margin: 0 10px;
    }

}