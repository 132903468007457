
#event{
}
#details{
    margin-top: 100px;
    text-align: center;
    padding: 0 100px;
    line-height: 2;
    }
    #details a{
        color: #002e5b;
        text-decoration: none;
        font-size: 15px;
    }

    .eventImage{
    float: left;
}

  #events h1,h3,h4{
    text-align: left;
    transform: translateX(30%);
  }

#details .summary-3{
    text-align: left;
    transform: translateX(0%);
  }
  
#event{
    display: grid;
    line-height: 2;
    grid-area: 2 / 2 / 3 / 3;
}

.pagination{
    background-color: gold !important;
    border: 1px solid gold !important;
    font-weight: bold !important;
}
.pagination:hover{
    background-color: black !important;
    color: white !important;
    border:1px solid gold  !important;
}

#news{
    display: inline-block;
}


@media only screen and (max-width: 1280px){
    #events{
        display: block;
        text-align: center;
        align-self: center;
        align-items: center;
        overflow: hidden;
    }
    #events h1{
        transform: translateX(0%);
        font-size: 18px;
        text-align: center;
    }
    #events h4{
        text-align: left;
        font-size: 14px;
        transform: translateX(0%);
    }
    #events img{
        width: 150px;
        height: 150px;
        display: none;
    }
    .eventImage{
        float: none;
        text-align: center;
    }


    #details{
        margin-top: 100px;
        padding: 10px;
    }
    #details h1,h3,h4{
        text-align: center;
        transform: translateX(0%);
      }
      #details h1{
          font-size: 20px;
      }
      #details h3{
          font-size: 16px;
      }
      #details h4{
          font-size: 14px;
      }
    
}