/* For 2560x1440p Monitor and Default Styles */
#about {
    display: grid;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 53px; /* 2vw = 25.6px * 2 */
    margin-bottom: 128px; /* 5vw = 25.6px * 5 */
}
#about p,
#about li {
    font-size: 21px; /* 0.833vw = 25.6px * 0.833 */
}

#who {
    border-radius: 13px; /* 0.521vw = 25.6px * 0.521 */
    line-height: 1.5;
    color: black;
    padding: 67px; /* 2.604vw = 25.6px * 2.604 */
}
#activities,
#aims {
    border-radius: 13px; /* 0.521vw = 25.6px * 0.521 */
    line-height: 1.5;
    color: black;
    padding: 67px; /* 2.604vw = 25.6px * 2.604 */

}

#about li {
    line-height: 51px; /* 2vw = 25.6px * 2 */
}

#chapters {
    background: linear-gradient(10deg, #f6ff5523, #ffd90041);
    border-radius: 13px; /* 0.521vw = 25.6px * 0.521 */
    line-height: 1.5;
    color: black;
    padding: 67px; /* 2.604vw = 25.6px * 2.604 */
}

#executives {
    line-height: 51px; /* 2vw = 25.6px * 2 */
    grid-area: 2 / 2 / 3 / 3;
    text-align: center;
    margin-left: 0px;
}
#executives li {
    list-style: none;
    margin: 27px 0px; /* 1.042vw = 25.6px * 1.042 */
    font-size: 24px; /* 0.938vw = 25.6px * 0.938 */
}
#executives img {
    float: left;
    width: 50px; /* Maintain small image size for desktop */
}

/* Responsive Design for Phones */
@media only screen and (max-width: 1130px) {
    #about {
        display: block;
        margin-bottom: 100px; /* 10vw = 11.3px * 10 */
    }
    #about li {
        text-align: left;
    }
    #executives {
        display: block;
        padding: 5px; /* 5vw = 11.3px * 5 */
    }
    #executives li {
        text-align: center;
    }
    #executives img {
        width: 130px; /* 12vw = 11.3px * 12 */
    }

    #who,
    #aims,
    #activities,
    #chapters,
    #relationships {
        margin-bottom: 113px; /* 10vw = 11.3px * 10 */
    }

    #who img,
    #objectives img,
    #activities img,
    #chapters img,
    #history img,
    #relationships img,
    #finance img {
        width: 90%; /* Percentage remains unchanged for responsiveness */
        max-width: 300px;
        height: auto;
    }
}

