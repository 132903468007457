html{
  font-family: "Kanit", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0px px grey; 
  border-radius: 10px;
  width: 0px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #171717; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #171717; 
}

body{
  padding: 10 0;
  margin: 0;
  font-family:"Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#shortcut {
    text-align: center;

  }
  #shortcut h1{
    margin-bottom: 67px;
  }
  #shortcut a{
    text-decoration: none;
    color: black;
  }

  #aboutShortcut{
    display: flex;
    background:linear-gradient(10deg, #f6ff5523, #ffd90041);
    text-align: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 7px;
    padding: 133px 0px;
    overflow: hidden;
  }
  #aboutShortcut h3,
  .shortcutStyle h3{
    line-height: 2;
    margin-bottom: 67px;
  }

  .shortcutStyle{
    max-width: 1067px;
    
    display: flex;
    background:linear-gradient(10deg, #f6ff5523, #ffd90000);
    text-align: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 7px;
    padding: 133px 10px;
    overflow: hidden;
  }
  #viewAll{
    text-decoration: none;
    background-color: gold;
    padding: 10px;
    border-radius: 5px;
    color: black;
    transition: all linear 200ms;
    margin-bottom: 80px;
  }
  #viewAll:hover{
    background-color: #002e5b;
    border: 1px gold solid;
    color: white;
  }
#banner{
  height: 100vh;
  margin-top: 0px;
}
.pageBanner{
  height: 50vh;
  
}
#bannerText {
  color: white;
}
#bannerText p{
  font-size: 24px;
  text-align: center;
}
.homeContent{
  padding: 133px 0px;
  text-align: center;
}

.homeContent h1{
  text-align: center;
}
.image{
  margin-bottom: 133px;
}


  #news {
    margin-bottom: 133px;
  }

  #latestNews{
    padding: 67px;
    display: inline-block;
    text-align: center;
  }
  
  #latestNews h1{
    margin-bottom: 67px;
  }

  #joinUs{
    background-color: gold;
    margin: 0px,50px;
    color: black;
    text-decoration: none;
    border-radius: 5px;
    padding: 10px;
    transition: all linear 200ms;
  }
  #joinUs:hover{
    background-color: #171717;
    border: 1px gold solid;
    color: white;
  }

  .button-2{
    color: black;
    background-color: gold;
    border: 1px solid gold;
    border-radius: 5px;
    font-size: 19px;
    padding: 13px;
    cursor: pointer;
    font-weight: bold;
    transition: all 400ms ease-in-out 0s;
  }
  
  .button-2:hover {
    padding: 12px;
  }

  .eventHover:hover{
    background-color: #171717;
    border: 1px gold solid;
    color: white;
  }
  p{
    font-size: 24px;
  }

  #form{
    margin-top: 100px;
    text-align: center;
  }
  #join{
    margin-top: 100px;
    text-align: center;
    line-height: 2;
    padding: 67px ;

  }
  #join p,
  #join li{
    font-size: 24px;
  }
  #join li {
    line-height: 3;
    text-align: left;
  }
  #join a{
    text-decoration: none;
    color: black;
    background-color: gold;
    padding: 13px;
    border-radius: 5px;
    transition: all linear 200ms;
  }
  #join a:hover{
    background-color: #171717;
    color: white;
    border: 1px solid gold;
  }
  #memberDetails{
  }
  #ads{
    text-align: center;
    overflow: hidden;
    margin-bottom: 133px;
  }
/*#news{
  display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: 2fr;
grid-column-gap: 37px;
grid-row-gap: 0px;

}*/


.what{
  background-color: darkgoldenrod;
}

.bannerPic{
  width: 150px;
}

#partners{
  text-align: center;
  padding: 13px 300px;
}


.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  font-weight: bolder;
  color: black;
}

.carousel{
  padding-bottom: 50px;
}
.carousel img{
  width: 250px;
}
  

  @media only screen and (max-width: 1130px) {
    .shortcutStyle img{
      width: 350px;
    }

    #form{
      margin-top: 100px;
    }
    #join p,
    #join li{
      font-size: 14px;
    }
    .bannerPic{
      width: 100px;
    }

    .button-2{
      font-size: 14px;
      padding: 10px;
    }

    p{
      font-size: 14px;
    }

    #join a{
      padding: 5px;
    }
    iframe{
      width: 350px;
    }
    #join{
      margin-top: 100px;
      padding: 10px;
    }
    #join li{
      text-align: left;
      line-height: 2;
      padding-bottom: 30px ;
    }
    #memberDetails{
      padding: 0px;
    }
    #ads img{
      width: 300px;
    }
    #news{
      display: block;
    }

    #partners{
      padding: 50px 30px;
    }

    .carousel img{
      width: 150px;
    }
  }
