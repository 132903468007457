#gallery{
    margin-top: 5.208vw;
}
#gallery a,
#fullGallery a{
    text-decoration: none;
    color: black;
    transition: 100ms all linear;
}
#gallery a:hover,
#fullGallery a:hover{
    color: gold;
    transform: scale(1.5);
}

#galleryCategories{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    overflow: auto;
    flex-direction: row;
}
#galleryCategories div{
    margin: 0px 2.604vw;
}
#galleryCategories img{
    width: 13.021vw;
}
#fullGallery{
    margin-top: 5.208vw;
}
@media only screen and (max-width: 1200px) {
    #galleryCategories{
        display: block;
        text-align: center;
        
    }
    #galleryCategories img{
        width: 200px;
    }
    #galleryCategories div{
        margin: 0;
    }
}