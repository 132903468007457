html{
  scroll-behavior: smooth;

}
#topContact{
  padding: 1.042vw;
  margin-left: 41.667vw;
  margin-bottom: 0px;
  border-radius: 2px;
  text-align: center;
  float: right;
  z-index: 10;
  overflow: hidden;
}
#topContact a{
  margin-right: 1.042vw;
  text-decoration: none;
  color: white;
}

#nav #topContact a{
  font-size: 0.729vw;

}
#sticky #topContact a{
  font-size: 0.521vw;
}


#nav,
#sticky{
  border-radius: 2px;
  align-items: center;
  text-align: left;
  padding: 1.042vw 0px;
  padding-left: 2.604vw;
  position: fixed;
  margin: 1.042vw 0px;
  top: 2;
  width: 100%;
  height: 6vh;
  z-index: 10;
  overflow: hidden;
  transition: all linear 500ms;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-content: flex-start;
}
#nav a{
  color: white;
  font-size: 1.667vh;

}


#sticky{
  background-color:white;
  margin-top: 0;
  position: fixed;
  top: 0;
  transition: all linear 500ms;
  border-bottom: 1px #002e5b solid;
}
#sticky a{
  color: black;
  font-size: 0.833vw;
}


.mobileSticky{
  position: fixed;
  top: 0;
  transition: all linear 500ms;
}
.mobileStickyBg{
  background-color: white;
}

nav li{
  display: inline-block;
}


nav a{
  text-decoration: none;
  color: black;
  font-weight: bolder;
  transition: all linear 0s 100ms;
  font-size: 0.938vw;
  margin: 0px 0.781vw;
  transition: all linear 200ms;
}
nav a:hover{
  text-decoration: underline;
  transition: all linear 200ms;
  text-decoration-color:gold ;

}

.navJoin{
  color: gold;
}
#logo{
  display: inline-block;
  font-size: 1.979vw;
  color: black;
  text-decoration: none;
}
#mobileLogo{
  display: none;
}

#menuItems{
  overflow: hidden;
  display: none;
  width: 100%;
  height: 100%;
  transition: all linear 200ms;
  transform: translateY(-500px);
  
}

#menuItems a {
  text-align: center;
  display: block;
  padding: 6px 0px;
  list-style: none;
  border-bottom: 1px solid;
  text-decoration: none;
  background-color: gold;
  color: black;
  transition: all linear 200ms;
}

#menuItems a:active{
  background-color: green;   
}
#banner,
.pageBanner{
  text-align: center;
  transition: all linear 500ms;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  height: 500px;
  
}

#bannerText,
.pageBannerText{
  color: white;
  font-weight: bold;
  text-align: center;
  position: relative;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
}

#mobileNav{
  position: fixed;
  width: 100%;
  z-index: 5;
  color: black;
  top: 1;
}
#background{
  margin-top: 2px;
  text-align: right;
  transition: all linear 200ms;
  background-color: white;

}
#menuButton{
  color: black;
  overflow: hidden;
  display: none;
  font-size: 30px;
  cursor: pointer;
  transition: all linear 200ms;
}




#news{
  text-align: center;
}

@media only screen and (max-width: 1400px){
  html{
      padding: 0px;
  }

  #background{
    padding: 20px 10px;
  }
  #mobileLogo{
      display: inline;
      float: left;
      
  }
  #topContact{
    padding: 10px 0px;
    font-size: 14px;

  }

  #banner{
    margin-top: 70px;
  }
  #nav,
  #sticky{
      display: none;
  }
  
  #menuButton{
      display: inline-block;
  }
  #menuItems{
      display: block;
  }
  #menuItems a{
    padding: 10px 0px;

  }
  #news{
    text-align: center;
    display: block;
  }
  
}


